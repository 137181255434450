.bannerList {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.bannerList .FormBox {
  flex: 1;
}
.btn-box {
  padding: 20px 20%;
  display: flex;
  justify-content: center;
}
.btn-box button {
  padding: 12px 30px;
}
