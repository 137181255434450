








































































































































































































































































































































































































































































.bannerList {
  display: flex;
  flex-direction: column;
  height: 100%;
  .FormBox {
    flex: 1;
  }
}
.btn-box {
  padding: 20px 20%;
  display: flex;
  justify-content: center;
  button {
    padding: 12px 30px;
  }
}
